export enum ShippingStatus {
  REFUND_SUCCESS = -70,
  REFUND_PENDING = -60,
  RETURN_TO_WAREHOUSE_COMPLETE = -50,
  RETURN_TO_WAREHOUSE = -40,
  EXCHANGE_REJECT = -30,
  RETURN_REJECT = -31,
  MISSING_REJECT = -32,
  EXCHANGE_APPROVED = -20,
  RETURN_APPROVED = -21,
  MISSING_APPROVED = -22,
  EXCHANGE_APPLY = -10,
  RETURN_APPLY = -11,
  MISSING_APPLY = -12,
  SUSPEND = -1,
  CANCELED = 0,
  WAITING_TO_DEPLOY = 10,
  DEPLOY_SUCCESS = 11,
  DEDUCTED = 15,
  PICKING = 20,
  OUT_OF_STOCK = 30,
  SHIPPING = 40,
  SHIPPING_FAILED = 50,
  SHIPPING_REJECT = 60,
  ARRIVED = 70,
  PICKED_UP = 80,
  FINISH = 90,
}

export enum ShippingStatusLabel {
  "退款成功" = -70,
  "等待退款" = -60,
  "退件驗收完成" = -50,
  "退件至倉庫，驗收中" = -40,
  "換貨申請失敗，請洽客服" = -30,
  "退貨申請失敗，請洽客服" = -31,
  "補貨申請失敗，請洽客服" = -32,
  "換貨申請成功" = -20,
  "退貨申請成功" = -21,
  "補貨申請成功" = -22,
  "收到換貨申請" = -10,
  "收到退貨申請" = -11,
  "收到補貨申請" = -12,
  "訂單異常" = -1,
  "取消訂單" = 0,
  "收到訂單" = 10,
  "已拋單" = 11,
  "撿貨處理中" = 15,
  "揀貨處理中" = 20,
  "商品缺貨，請等候信件通知" = 30,
  "配送中" = 40,
  "配送異常" = 50,
  "包裹逾期未取退回中" = 60,
  "已到達門市" = 70,
  "已取件" = 80,
  "訂單完成" = 90,
}
