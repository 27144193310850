import { FilterTwoTone } from "@ant-design/icons";
import DashBoard from "@component/Common/DashBoard";
import PageTitle from "@component/PageTitle";
import TableIntegrated from "@component/Table/TableIntegrated";
import { Button, message } from "antd";
import React, { ReactText, useCallback, useEffect, useMemo, useState } from "react";
import { ContentWrapper, FlexBox, Header, TableWrapper } from "src/styles/common";
import orderPaymentApi, { OrderPaymentListFilter, OrderPaymentResp } from "@api/finance/OrderPaymentApi";
import { ColumnsType } from "antd/lib/table";
import { PaymentMethodList } from "@page/Order/PaymentMethodValue";
import moment from "moment";
import OrderPaymentFilter, { orderPaymentStatusOptions } from "./OrderPaymentFilter";

const OrderPaymentListComponent = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedPaymentIds, setSelectedPaymentIds] = useState<number[]>([]);
  const [orderPaymentListResp, setOrderPaymentListResp] = useState<OrderPaymentResp>({
    results: [],
    count: 0,
    captureFailed: 0,
    waitingForCapture: 0,
    next: "",
    previous: "",
  });
  const [listParams, setListParams] = useState<OrderPaymentListFilter>({
    offset: 0,
    limit: 20,
  });
  const { results: orderPaymentList, count: totalCount } = orderPaymentListResp;

  const { limit, offset } = listParams;

  const dashBoardData = useMemo(
    () => [
      { name: "待請款筆數", value: orderPaymentListResp.waitingForCapture },
      { name: "請款失敗筆數", value: orderPaymentListResp.captureFailed },
    ],
    [orderPaymentListResp.captureFailed, orderPaymentListResp.waitingForCapture],
  );

  const fetchOrderPaymentList = useCallback(async (params: OrderPaymentListFilter) => {
    setIsFetching(true);
    try {
      const response = await orderPaymentApi.fetchOrderPaymentList(params);
      setOrderPaymentListResp(response);
      return "success";
    } catch (e) {
      return "error";
    } finally {
      setIsFetching(false);
    }
  }, []);

  const handleUpdateParams = (params: any) => {
    setListParams({ ...listParams, ...params });
  };

  const tableColumns: ColumnsType<any> = [
    {
      key: "paymentStatus",
      dataIndex: "paymentStatus",
      title: "請款狀態",
      align: "center",
      width: 100,
      render: (status: number) => orderPaymentStatusOptions.find((option) => option.value === status)?.label || "?",
    },
    {
      key: "type",
      dataIndex: "type",
      title: "訂單類型",
      align: "center",
      width: 100,
    },
    {
      key: "orderNumber",
      dataIndex: "orderNumber",
      title: "母訂單編號",
      align: "center",
      width: 160,
    },
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "訂單建立時間",
      align: "center",
      width: 180,
      render: (createdAt: string) => (createdAt ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss") : "-"),
    },
    {
      key: "price",
      dataIndex: "price",
      title: "訂單金額",
      align: "center",
      width: 100,
    },
    {
      key: "transactionId",
      dataIndex: "transactionId",
      title: "TapPay查詢碼",
    },
    {
      key: "paymentMethod",
      dataIndex: "paymentMethod",
      title: "付款方式",
      align: "center",
      width: 120,
      render: (paymentMethod: number) => PaymentMethodList[paymentMethod],
    },
    {
      key: "applyCaptureAt",
      dataIndex: "applyCaptureAt",
      title: "請款時間",
      align: "center",
      width: 180,
      render: (applyCaptureAt: string) => (applyCaptureAt ? moment(applyCaptureAt).format("YYYY-MM-DD HH:mm:ss") : "-"),
    },
    {
      key: "capturedAt",
      dataIndex: "capturedAt",
      title: "請款完成時間",
      width: 180,
      align: "center",
      render: (capturedAt: string) => (capturedAt ? moment(capturedAt).format("YYYY-MM-DD HH:mm:ss") : "-"),
    },
    {
      key: "errMsg",
      dataIndex: "errMsg",
      title: "異常原因",
      width: 200,
    },
  ];

  const handleOnCheck = (value: ReactText[]) => {
    setSelectedPaymentIds(value as number[]);
  };

  const handleOnBatchFinish = async () => {
    try {
      if (selectedPaymentIds.length === 0) return;
      await orderPaymentApi.batchFinishOrderPayment(selectedPaymentIds);
      await fetchOrderPaymentList(listParams);
      message.success("批次處理完成");
    } catch (e) {
      message.error("批次處理失敗");
    }
  };

  const handleOnExport = async () => {
    try {
      await orderPaymentApi.exportOrderPaymentList(listParams);
      message.success("匯出資料準備中，成功後會寄信通知 ");
    } catch (e: any) {
      message.error("匯出失敗");
    }
  };

  useEffect(() => {
    fetchOrderPaymentList(listParams);
  }, [fetchOrderPaymentList, listParams]);

  return (
    <ContentWrapper>
      <PageTitle title="訂單請款清單" />
      <Header>
        <FlexBox>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
          <Button onClick={handleOnExport}>匯出</Button>
        </FlexBox>
      </Header>
      <OrderPaymentFilter show={showFilter} updateParams={handleUpdateParams} />
      <TableWrapper>
        <TableIntegrated
          footer={() => {
            return (
              <Button type="primary" onClick={handleOnBatchFinish} disabled={selectedPaymentIds.length === 0}>
                處理完成
              </Button>
            );
          }}
          dashboardComponent={<DashBoard dataset={dashBoardData} isFetching={isFetching} />}
          isPagination
          paginationConfig={{
            totalCount,
            offset,
            limit,
            updateFilterParams: (filter: OrderPaymentListFilter) => {
              setListParams(filter);
            },
            filterParams: listParams,
          }}
          dataSource={orderPaymentList}
          columns={tableColumns}
          scroll={{ y: 500 }}
          tableLayout="auto"
          loading={isFetching}
          rowKey="paymentId"
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedPaymentIds,
          }}
        />
      </TableWrapper>
    </ContentWrapper>
  );
};

export default OrderPaymentListComponent;
