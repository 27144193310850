import { Button, Form, Input, Modal, Select, DatePicker } from "antd";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import { SupplementDetail, EditSupplementDetail } from "@page/VendorSupplementPage/interface";
import { supplementPurposeMap, SupplementPurpose } from "@constant/vendor";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import {
  fetchFilterVendorList,
  loadMoreFilterVendorList,
  addSupplement,
  editSupplement,
  resetFilterVendorListResult,
} from "@redux/vendorSlice";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useDebounce from "@hooks/useDebounce";
import moment from "moment";
import { RootState } from "@redux/rootReducer";
import { fetchSupplementVenderRelation } from "@api/vendorApi";
import { ValidateStatus } from "antd/lib/form/FormItem";
import useSalesChannelOptions from "@hooks/useSalesChannelOptions";

const WrapperForm = styled(Form)`
  padding: 32px 28px 20px 28px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ButtonContainer = styled.div`
  margin-top: auto;
  text-align: right;
`;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
const StyledInput = styled(Input)`
  width: 395px;
`;
const Row = styled.div`
  margin-right: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.div`
  font-size: 14px;
  align-self: flex-start;
  line-height: 32px;
`;
const Red = styled.span`
  color: red;
`;

export enum ContactMode {
  M_DOMESTIC_BUYOUT = 1,
  M_FOREIGN_BUYOUT = 2,
  M_CONSIGNMENT = 3,
  M_TRANSFER = 4,
}
export const ContactModeLabel = [
  { value: ContactMode.M_DOMESTIC_BUYOUT, label: "國內買斷" },
  { value: ContactMode.M_FOREIGN_BUYOUT, label: "國外買斷" },
  { value: ContactMode.M_CONSIGNMENT, label: "寄倉" },
  { value: ContactMode.M_TRANSFER, label: "轉單" },
];

interface Props {
  isModalVisible: boolean;
  current?: Partial<SupplementDetail>;
  toggleModal: () => void;
  isEdit: boolean;
}

const EditModal: FC<Props> = (props) => {
  const { isModalVisible, toggleModal, current, isEdit } = props;

  const dispatch = useAppDispatch();
  const { filterVendorListResult } = useSelector((state: RootState) => state.vendorSlice);

  const [form] = Form.useForm<Partial<EditSupplementDetail>>();
  const salesChannelOptions = useSalesChannelOptions();
  const { sku: skuValue } = form.getFieldsValue();

  const [skuValid, setSkuValid] = useState<{
    sku?: string;
    salesChannel?: { id: number; modes: number[] }[];
    selectedSalesChannel?: number;
    salesChannelOptions?: { label: string; value: number }[];
    validateStatus?: ValidateStatus;
    errorMsg?: string | null;
    options?: { label: string; value: number }[];
  }>({});

  useEffect(() => {
    if (current && isModalVisible) {
      form.setFieldsValue({
        supplementPurpose: current.supplementPurpose,
        salesPlanId: current.salesPlanId,
        sku: current.sku,
        extraAmount: current.extraAmount,
        estimatedSupplementDate: moment(current.estimatedSupplementDate),
        supplementDescription: current.supplementDescription,
      });
    }
  }, [current, form, isModalVisible]);

  function handleOk() {
    const payload = form.getFieldsValue();
    if (current && isEdit) {
      dispatch(editSupplement({ ...payload, vendor: payload.vendor || current.vendor?.vendorId }));
    } else {
      dispatch(addSupplement({ ...payload, vendor: payload.vendor || current?.vendor?.vendorId }));
    }
    form.resetFields();
    toggleModal();
  }

  function handleCancel() {
    form.resetFields();
    setSkuValid({});
    toggleModal();
  }

  const handleOnVendorLoadMore = useCallback(() => {
    dispatch(loadMoreFilterVendorList());
  }, [dispatch]);

  const vendorOptions = useMemo(() => {
    const { next, results } = filterVendorListResult;
    const options = results.map((vdr) => (
      <Select.Option key={vdr.id} value={vdr.id}>
        {vdr.name}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnVendorLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [filterVendorListResult, handleOnVendorLoadMore]);

  const handleOnVendorSearch = useDebounce((value: string) => {
    dispatch(fetchFilterVendorList(value));
  }, 300);

  const handleOnVendorChange = (value: number) => {
    if (skuValue) {
      fetchSkuSearch(skuValue, value);
    }
  };

  const handleOnSalesChannelChange = (value: number) => {
    const selectedSalesChannelOption = skuValid.salesChannel?.filter((item) => item.id === value);
    setSkuValid({
      ...skuValid,
      selectedSalesChannel: value,
      options:
        selectedSalesChannelOption &&
        selectedSalesChannelOption[0]?.modes.map((mode) => ({
          label: ContactModeLabel.find((el) => el.value === mode)?.label || "",
          value: mode,
        })),
    });
  };

  const fetchSkuSearch = useCallback(
    async (value: string, vendor?: number) => {
      if (value.length === 0) {
        setSkuValid({});
        return;
      }
      try {
        setSkuValid({ validateStatus: "validating" });
        const response = await fetchSupplementVenderRelation({ sku: value.trim() || "", vendor });
        const { sku, salesChannel } = response;
        salesChannel.forEach((channel) => {
          if (channel.modes?.length === 0) {
            throw new Error("查無對應合作關係,請重新確認SKU");
          }
          if (
            channel.modes?.includes(ContactMode.M_DOMESTIC_BUYOUT) ||
            channel.modes?.includes(ContactMode.M_FOREIGN_BUYOUT)
          ) {
            throw new Error("此廠商的合作模式為買斷，無法進行補單");
          }
          setSkuValid({
            sku,
            salesChannel,
            validateStatus: "success",
            errorMsg: null,
            options: salesChannel[0]?.modes.map((mode) => ({
              label: ContactModeLabel.find((el) => el.value === mode)?.label || "",
              value: mode,
            })),
            salesChannelOptions: salesChannel.map((channelItem) => ({
              label: salesChannelOptions.find((el) => el.value === channelItem.id)?.label || "",
              value: channelItem.id,
            })),
          });
          if (channel.modes.length === 1) {
            form.setFieldsValue({ mode: channel.modes[0] });
          }
          if (salesChannel.length === 1) {
            form.setFieldsValue({ salesChannel: salesChannel[0].id });
          }
        });
      } catch (error: any) {
        form.setFieldsValue({ mode: undefined });
        setSkuValid({
          sku: undefined,
          salesChannel: undefined,
          validateStatus: "error",
          errorMsg: error.message,
          options: undefined,
          salesChannelOptions: undefined,
        });
      }
    },
    [form, salesChannelOptions],
  );

  const handleOnSkuSearch = useDebounce(async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const trimmedValue = value.trim();
    const { vendor } = form.getFieldsValue();

    fetchSkuSearch(trimmedValue, vendor);
    form.setFieldsValue({ sku: trimmedValue });
  }, 500);

  useEffect(() => {
    if (isModalVisible) {
      dispatch(fetchFilterVendorList(""));
    }
    return () => {
      dispatch(resetFilterVendorListResult());
    };
  }, [dispatch, isModalVisible]);

  return (
    <Modal
      visible={isModalVisible}
      width={659}
      bodyStyle={{ height: 600, padding: 0 }}
      closable={false}
      footer={false}
      maskClosable={false}
    >
      <WrapperForm form={form} name="basic" onFinish={handleOk}>
        {current?.vendor && (
          <Row>
            <Title>{`原廠商: ${current?.vendor.vendorName}`}</Title>
          </Row>
        )}
        <Row>
          <Title>
            廠商<Red>*</Red>
          </Title>
          <Form.Item name="vendor" rules={current ? undefined : [{ required: true, message: "" }]}>
            <Select
              showSearch
              filterOption={false}
              style={{ width: 393 }}
              placeholder="請選擇"
              onSearch={handleOnVendorSearch}
              onChange={handleOnVendorChange}
            >
              {vendorOptions}
            </Select>
          </Form.Item>
        </Row>
        <Row>
          <Title>
            SKU<Red>*</Red>
          </Title>
          <Form.Item
            name="sku"
            rules={[{ required: true, message: "" }]}
            validateStatus={skuValid.validateStatus}
            help={skuValid.errorMsg}
            hasFeedback
          >
            <StyledInput onChange={handleOnSkuSearch} />
          </Form.Item>
        </Row>
        <Row>
          <Title>
            通路<Red>*</Red>
          </Title>
          <Form.Item name="salesChannel" rules={[{ required: true, message: "" }]}>
            <Select
              style={{ width: 393 }}
              placeholder="請選擇"
              options={skuValid.salesChannelOptions}
              disabled={!skuValid.salesChannelOptions || skuValid.salesChannelOptions?.length === 0}
              onChange={handleOnSalesChannelChange}
            />
          </Form.Item>
        </Row>
        <Row>
          <Title>
            合作模式<Red>*</Red>
          </Title>
          <Form.Item name="mode" rules={[{ required: true, message: "" }]}>
            <Select
              style={{ width: 393 }}
              placeholder="請選擇"
              options={skuValid.options}
              disabled={!skuValid.options || skuValid.options?.length === 0}
            />
          </Form.Item>
        </Row>
        <Row>
          <Title>
            補單緣由<Red>*</Red>
          </Title>
          <Form.Item name="supplementPurpose" rules={[{ required: true, message: "" }]}>
            <Select style={{ width: 393 }} placeholder="請選擇">
              {Object.keys(supplementPurposeMap).map((el) => (
                <Select.Option value={Number(el)}>{supplementPurposeMap[Number(el)]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Row>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) =>
            getFieldValue("supplementPurpose") === SupplementPurpose.OTHERS && (
              <Row>
                <Title>補單說明</Title>
                <Form.Item name="supplementDescription" rules={[{ required: true, message: "" }]}>
                  <StyledInput />
                </Form.Item>
              </Row>
            )
          }
        </Form.Item>

        <Row>
          <Title>
            預期補單時間<Red>*</Red>
          </Title>
          <Form.Item name="estimatedSupplementDate" rules={[{ required: true, message: "" }]}>
            <DatePicker style={{ width: 393 }} picker="month" format="YYYY-MM" />
          </Form.Item>
        </Row>
        <Row>
          <Title>銷售頁方案ID(選填)</Title>
          <Form.Item name="salesplanId">
            <StyledInput />
          </Form.Item>
        </Row>
        <Row>
          <Title>
            額外增減金額<Red>*</Red>
          </Title>
          <Form.Item name="extraAmount" rules={[{ required: true, message: "" }]}>
            <StyledInput type="number" />
          </Form.Item>
        </Row>
        <ButtonContainer>
          <CancelButton type="default" onClick={handleCancel}>
            取消
          </CancelButton>
          <Button type="primary" htmlType="submit" disabled={skuValid.validateStatus === "error"}>
            確定
          </Button>
        </ButtonContainer>
      </WrapperForm>
    </Modal>
  );
};

export default EditModal;
