import { PlusCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { RadioChangeEvent } from "antd/lib/radio";
import { FetchSaleListParams, SaleListItem } from "@api/saleApi";
import { CategoryListResult, TagListResult } from "@api/promotionApi";
import {
  batchDeleteSalePages,
  fetchSalesCategoryList,
  singleDeleteSalePage,
  updateSaleListParams,
  updateSelectedSaleIds,
  updateShowCSVImportPopup,
  fetchTagBindRelation,
  fetchCategoryBindRelation,
  fetchSalePageBindRelation,
  deleteBrand,
  deleteCategory,
} from "@redux/promotionSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Input, Modal, Pagination, Select, Table, Radio, Form } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { ChangeEvent, ReactText, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import PromotionBindType from "@constant/PromotionBindType";
import AddSalePage from "./AddSalePage";
import ImportCSV from "./ImportCSV";
import AddBrandPopup from "./AddBrandPopup";
import AddCategoryPopup from "./AddCategoryPopup";

type Props = {
  setBindType: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const Wrapper = styled(Form)`
  border: 1px solid #d9d9d9;
  margin-bottom: 36px;
`;
const Header = styled.div`
  padding: 10px 20px;
  background-color: #fafafa;
  color: ${({ theme }) => theme.colorNeutral600};
  font-size: 14px;
`;
const Body = styled.div`
  padding: 16px 16px 34px 20px;
`;
const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const AddButton = styled(Button)`
  margin-right: 10px;
`;
const ImportButton = styled(Button)`
  margin-right: 10px;
`;
const TableToolWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TablePageInfo = styled.div`
  font-size: 14px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const DeleteText = styled.p`
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
  cursor: pointer;
  margin: 0;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 100px 10px;
  border: 1px solid #f0f0f0;
`;
const DeleteButton = styled(Button)`
  position: absolute;
  left: 26px;
  bottom: 47px;
`;
const TablePagination = styled(Pagination)`
  position: absolute;
  right: 21px;
  bottom: 47px;
`;

const CustomRadioWrapper = styled(Radio.Group)`
  padding-bottom: 30px;
`;

const CustomRadio = styled(Radio)`
  margin-bottom: 8px;
  display: block;
`;

const Row = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;

const CustomWrapper = styled.div``;

enum FilterTypeValue {
  SKU,
  NAME,
}

const pageSizeOptions = [20, 50, 100];

const filterOptions = [
  {
    label: "SKU",
    value: FilterTypeValue.SKU,
  },
  {
    label: "名稱",
    value: FilterTypeValue.NAME,
  },
];

const ManageSalePage = (props: Props) => {
  const { setBindType } = props;
  const dispatch = useAppDispatch();
  const {
    isFetchingSaleList,
    saleListParams,
    selectedSaleIds,
    promotionDetail,
    showCSVImportPopup,
    tagList,
    categoryList,
    salePageList,
  } = useSelector((state: RootState) => state.promotion);
  const { isFetchingSaleList: isFetchingAddSales } = useSelector((state: RootState) => state.promotionAddSales);

  const [filterType, setFilterType] = useState<FilterTypeValue>(FilterTypeValue.SKU);
  const [inputValue, setInputValue] = useState<string>("");
  const [showAddSalePage, setShowAddSalePage] = useState<boolean>(false);
  const [showAddCategory, setShowAddCategory] = useState<boolean>(false);
  const [showAddBrand, setShowAddBrand] = useState<boolean>(false);
  const [campaignType, setCampaignType] = useState<number>();
  const [form] = Form.useForm();

  const { limit, offset } = saleListParams;
  const { tag: tagId } = promotionDetail;

  useEffect(() => {
    dispatch(fetchSalesCategoryList());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      id: tagId,
      type: PromotionBindType.SALEPAGE,
    };
    dispatch(fetchSalePageBindRelation({ ...saleListParams, ...params }));
  }, [dispatch, saleListParams, tagId]);

  useEffect(() => {
    const params = {
      id: tagId,
      type: PromotionBindType.TAG,
      limit: 500,
      offset: 0,
    };
    dispatch(fetchTagBindRelation(params));
  }, [dispatch, tagId]);

  useEffect(() => {
    const params = {
      id: tagId,
      type: PromotionBindType.CATEGORY,
      limit: 500,
      offset: 0,
    };
    dispatch(fetchCategoryBindRelation(params));
  }, [dispatch, tagId]);

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  const handleOnClosePopup = () => {
    setShowAddSalePage(false);
    setShowAddCategory(false);
    setShowAddBrand(false);
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...saleListParams,
      limit: value as number,
    };
    const params = {
      id: tagId,
      type: PromotionBindType.SALEPAGE,
    };

    dispatch(updateSaleListParams(filter));
    dispatch(fetchSalePageBindRelation({ ...filter, ...params }));
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...saleListParams,
      offset: (page - 1) * limit,
    };
    const params = {
      id: tagId,
      type: PromotionBindType.SALEPAGE,
    };

    dispatch(updateSaleListParams(filter));
    dispatch(fetchSalePageBindRelation({ ...filter, ...params }));
  };

  const handleOnFilterTypeSelect = (value: SelectValue) => {
    setFilterType(value as FilterTypeValue);
  };

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const handleOnCheck = (value: ReactText[]) => {
    dispatch(updateSelectedSaleIds(value as number[]));
  };

  const handleOnAdd = () => {
    if (tagId < 0) {
      Modal.warning({
        title: "須先建立此筆資料，才能新增銷售頁",
        okText: "我知道了",
      });
    } else {
      setShowAddSalePage(true);
    }
  };

  const handleOnAddCategory = () => {
    if (tagId < 0) {
      Modal.warning({
        title: "須先建立此筆資料，才能指定分類",
        okText: "我知道了",
      });
    } else {
      setShowAddCategory(true);
    }
  };

  const handleOnDeleteCategory = (categoryId: number) => () => {
    Modal.confirm({
      title: "你確定要刪除嗎？",
      content: "",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(deleteCategory(categoryId));
      },
    });
  };
  const handleOnAddBrand = () => {
    if (tagId < 0) {
      Modal.warning({
        title: "須先建立此筆資料，才能指定品牌",
        okText: "我知道了",
      });
    } else {
      setShowAddBrand(true);
    }
  };
  const handleOnDeleteBrand = (selectedBrandId: number) => () => {
    Modal.confirm({
      title: "你確定要刪除嗎？",
      content: "",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(deleteBrand(selectedBrandId));
      },
    });
  };

  const handleOnImport = () => {
    if (tagId < 0) {
      Modal.warning({
        title: "須先建立此筆資料，才能新增銷售頁",
        okText: "我知道了",
      });
    } else {
      dispatch(updateShowCSVImportPopup(true));
    }
  };

  const handleOnSingleDelete = (pageId: number) => () => {
    dispatch(singleDeleteSalePage(pageId));
  };

  const handleOnBatchDelete = () => {
    dispatch(batchDeleteSalePages());
  };

  const handleCleanFilter = () => {
    setInputValue("");
    const salePageParams = {
      id: tagId,
      type: PromotionBindType.SALEPAGE,
      limit: 20,
      offset: 0,
    };
    dispatch(fetchSalePageBindRelation(salePageParams));
  };

  const handleOnFilterSubmit = () => {
    if (tagId > -1) {
      const params: FetchSaleListParams = {
        offset: 0,
        limit: saleListParams.limit,
      };
      switch (filterType) {
        case FilterTypeValue.SKU:
          params.sku = inputValue;
          params.pageNameQ = undefined;
          break;
        case FilterTypeValue.NAME:
          params.sku = undefined;
          params.pageNameQ = inputValue;
          break;
        default:
      }
      dispatch(updateSaleListParams(params));

      const salePageParams = {
        ...params,
        id: tagId,
        type: PromotionBindType.SALEPAGE,
      };

      dispatch(fetchSalePageBindRelation(salePageParams));
    }
  };

  const tableColumns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
    },
    {
      title: "SKU",
      key: "skuList",
      dataIndex: "skuList",
      width: 170,
      render: (value: SaleListItem["skuList"]) => {
        if (value.length === 0) return "N/A";
        return (
          <div>
            {value.map((sku) => (
              <p>{sku}</p>
            ))}
          </div>
        );
      },
    },
    {
      title: "名稱",
      key: "name",
      dataIndex: "name",
      width: 340,
    },
    {
      title: "品牌",
      key: "brands",
      dataIndex: "brands",
      width: 165,
      render: (value: SaleListItem["brands"]) => {
        if (value.length === 0) return "N/A";
        return (
          <div>
            {value.map((brand) => (
              <p>{brand}</p>
            ))}
          </div>
        );
      },
    },
    {
      title: "價錢",
      key: "planSpecialPriceRange",
      dataIndex: "planSpecialPriceRange",
      render: (value: SaleListItem["planSpecialPriceRange"]) => {
        const { minPrice, maxPrice } = value;
        return `$${minPrice} - $${maxPrice}`;
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      render: (value: any, data: SaleListItem) => <DeleteText onClick={handleOnSingleDelete(data.id)}>刪除</DeleteText>,
    },
  ];

  const categoryColums = [
    {
      title: "分類",
      key: "name",
      dataIndex: "name",
      render: (value: string, data: CategoryListResult) => {
        const { path, level } = data;
        const replacePath = path
          .replace("_root_/", path.match(/\//g)?.length === level ? "" : "其他/")
          .replaceAll("/", " / ");
        return (
          <Row justify="space-between">
            {replacePath}
            <DeleteText onClick={handleOnDeleteCategory(data.id)}>刪除</DeleteText>
          </Row>
        );
      },
    },
  ];

  const brandColums = [
    {
      title: "品牌",
      key: "name",
      dataIndex: "name",
      render: (value: string, data: TagListResult) => (
        <Row justify="space-between">
          {value}
          <DeleteText onClick={handleOnDeleteBrand(data.id)}>刪除</DeleteText>
        </Row>
      ),
    },
  ];

  const onChange = (e: RadioChangeEvent) => {
    setCampaignType(e.target.value);
    setBindType(e.target.value);
  };

  useEffect(() => {
    if (salePageList.result.results.length > 0) {
      form.setFieldsValue({
        bindType: 1,
      });
      setCampaignType(1);
    }
    if (tagList.result.results.length > 0) {
      form.setFieldsValue({
        bindType: 2,
      });
      setCampaignType(2);
    }
    if (categoryList.result.results.length > 0) {
      form.setFieldsValue({
        bindType: 3,
      });
      setCampaignType(3);
    }
  }, [categoryList.result.results.length, form, salePageList.result.results.length, tagList.result.results.length]);

  return (
    <Wrapper form={form}>
      <Header>選擇銷售頁</Header>
      <Body>
        <Form.Item name="bindType" shouldUpdate>
          <CustomRadioWrapper onChange={onChange}>
            <CustomRadio value={1}>銷售頁</CustomRadio>
            <CustomRadio value={2}>指定品牌</CustomRadio>
            <CustomRadio value={3}>指定分類</CustomRadio>
          </CustomRadioWrapper>
        </Form.Item>

        {campaignType === PromotionBindType.TAG && (
          <CustomWrapper>
            <TableHeader>
              <TableToolWrapper>
                <AddButton type="primary" icon={<PlusCircleFilled />} onClick={handleOnAddBrand}>
                  新增
                </AddButton>
              </TableToolWrapper>
            </TableHeader>
            <TableContainer>
              <Table
                dataSource={tagList.result.results}
                columns={brandColums}
                scroll={{ x: "max-content" }}
                tableLayout="auto"
                pagination={false}
                loading={isFetchingSaleList}
                rowKey="id"
              />
            </TableContainer>
          </CustomWrapper>
        )}
        {campaignType === PromotionBindType.CATEGORY && (
          <CustomWrapper>
            <TableHeader>
              <TableToolWrapper>
                <AddButton
                  type="primary"
                  icon={<PlusCircleFilled />}
                  onClick={handleOnAddCategory}
                  disabled={categoryList.result.results.length >= 3}
                >
                  新增
                </AddButton>
              </TableToolWrapper>
            </TableHeader>
            <TableContainer>
              <Table
                dataSource={categoryList.result.results}
                columns={categoryColums}
                scroll={{ x: "max-content" }}
                tableLayout="auto"
                pagination={false}
                loading={isFetchingSaleList || isFetchingAddSales}
                rowKey="id"
              />
            </TableContainer>
          </CustomWrapper>
        )}
        {campaignType === PromotionBindType.SALEPAGE && (
          <CustomWrapper>
            <TableHeader>
              <TableToolWrapper>
                <AddButton type="primary" icon={<PlusCircleFilled />} onClick={handleOnAdd}>
                  新增
                </AddButton>
                <ImportButton onClick={handleOnImport}>批次匯入</ImportButton>
                <Input.Group compact>
                  <Select value={filterType} options={filterOptions} onChange={handleOnFilterTypeSelect} />
                  <Input style={{ width: "70%" }} value={inputValue} onChange={handleOnInputChange} />
                </Input.Group>
                <Button onClick={handleOnFilterSubmit}>篩選</Button>
                <Button onClick={handleCleanFilter}>清除篩選資料</Button>
              </TableToolWrapper>
              <TablePageInfo>
                總共
                {salePageList.result.count}
                筆, 每頁顯示
                <PageSelect value={limit} onChange={handleOnPageSizeChange}>
                  {pageSizeOptions.map((option) => (
                    <Select.Option key={option} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </PageSelect>
                筆
              </TablePageInfo>
            </TableHeader>
            <TableContainer>
              <Table
                dataSource={salePageList.result.results}
                columns={tableColumns}
                scroll={{ x: "max-content" }}
                rowSelection={{
                  onChange: handleOnCheck,
                  selectedRowKeys: selectedSaleIds,
                }}
                tableLayout="auto"
                pagination={false}
                loading={isFetchingSaleList}
                rowKey="id"
              />
              <DeleteButton onClick={handleOnBatchDelete} disabled={selectedSaleIds.length === 0}>
                刪除
              </DeleteButton>
              <TablePagination
                current={currentPage}
                total={salePageList.result.count}
                onChange={handleOnPageChange}
                showSizeChanger={false}
                pageSize={limit}
              />
            </TableContainer>
          </CustomWrapper>
        )}
      </Body>
      {showAddSalePage && <AddSalePage onClose={handleOnClosePopup} />}
      {showAddBrand && <AddBrandPopup onClose={handleOnClosePopup} />}
      {showAddCategory && <AddCategoryPopup onClose={handleOnClosePopup} />}
      {showCSVImportPopup && <ImportCSV />}
    </Wrapper>
  );
};

export default ManageSalePage;
