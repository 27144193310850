import { ExclamationCircleOutlined, FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { ActionType, RedeemMethod, VipRule } from "@api/vipRuleApi";
import {
  fetchDeleteVipRule,
  fetchUpdateVipRuleActive,
  fetchVipRuleList,
  resetVipRuleSlice,
  setVipRuleListParams,
  VipRuleState,
} from "@redux/vipRuleSlice";
import { Button, Checkbox, Modal, Row, Select, Switch, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import VipRuleListFilter from "./VipRuleListFilter";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
  position: relative;
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const BatchDelete = styled.div`
  position: absolute;
  left: 16px;
  bottom: 32px;
`;

export default function VipRulePage() {
  const dispatch = useDispatch();
  const { isFetching, vipRuleListParams, vipRuleListResults, isEditDone } = useSelector(VipRuleState);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [checkIds, setCheckIds] = useState<Set<number>>(new Set());

  const openVipRulePage = (id?: number) => {
    window.open(`${window.location.href}/edit/${id || "new"}`);
  };

  const setPageLimit = (value: SelectValue) => {
    dispatch(setVipRuleListParams({ ...vipRuleListParams, limit: parseInt(value as string, 10) }));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(
      fetchVipRuleList({
        ...vipRuleListParams,
        offset: (value - 1) * vipRuleListParams.limit!,
      }),
    );
  };

  const fetchBatchDelete = (ids: number[]) => {
    Modal.confirm({
      title: "你要刪除這些優惠嗎",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(fetchDeleteVipRule({ ids }));
      },
      okText: "是",
      cancelText: "否",
    });
  };

  const columns: ColumnsType<VipRule> = [
    {
      key: "checkbox",
      dataIndex: "checkbox",
      title: "",
      render: (_: any, others: VipRule) => <Checkbox onChange={() => setCheckIds(new Set(checkIds.add(others.id)))} />,
    },
    {
      key: "id",
      dataIndex: "id",
      title: "ID",
    },
    {
      key: "isActive",
      dataIndex: "isActive",
      title: "啟用",
      render: (isActive: boolean, others: VipRule) => (
        <Switch
          checked={isActive}
          onChange={() =>
            dispatch(
              fetchUpdateVipRuleActive({
                payload: {
                  title: others.title,
                  isActive: !isActive,
                },
                vipRuleId: others.id,
              }),
            )
          }
        />
      ),
    },
    {
      key: "title",
      dataIndex: "title",
      title: "名稱",
    },
    {
      key: "redeemCode",
      dataIndex: "redeemCode",
      title: "Redeem Code",
      render: (_: any, others: VipRule) => (
        <div>{others.redeemMethod === RedeemMethod.SINGLE ? others.redeemCode : `${others.codePrefix} - 隨機`}</div>
      ),
    },
    {
      key: "content",
      dataIndex: "content",
      title: "優惠內容",
      render: (_: any, others: VipRule) => (
        <div>
          {others.actionType === ActionType.DISCOUNT ? `折抵金額$${others.discount}` : `延長到期日${others.discount}天`}
        </div>
      ),
    },
    {
      key: "usePeriod",
      dataIndex: "usePeriod",
      title: "使用時間",
      render: (_: any, others: VipRule) => (
        <div>{others.useStartAt ? `${others.useStartAt} - ${others.useEndAt}` : "不限"}</div>
      ),
    },
    {
      key: "perCodeLimit",
      dataIndex: "perCodeLimit",
      title: "使用次數",
      render: (_: any, others: VipRule) => <div>{others.perCodeLimit || "不限"}</div>,
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      fixed: "right",
      render: (_: any, others: VipRule) => (
        <div>
          <Button type="link" onClick={() => openVipRulePage(others.id)}>
            編輯
          </Button>
          <Button type="link" onClick={() => fetchBatchDelete([others.id])}>
            刪除
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
    dispatch(
      fetchVipRuleList({
        ...vipRuleListParams,
        offset: 0,
      }),
    );
  }, [dispatch, vipRuleListParams]);

  useEffect(() => {
    if (isEditDone) {
      setPage(1);
      dispatch(
        fetchVipRuleList({
          ...vipRuleListParams,
          offset: 0,
        }),
      );
    }
  }, [dispatch, vipRuleListParams, isEditDone]);

  useEffect(() => {
    return () => {
      dispatch(resetVipRuleSlice());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title="VIP 優惠" />
      <Row justify="space-between">
        <div>
          <CustomButton
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => openVipRulePage()}
          >
            新增優惠
          </CustomButton>
          <CustomButton icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </CustomButton>
        </div>
        <Row align="middle">
          <PageText>{`總共${vipRuleListResults.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={setPageLimit}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <VipRuleListFilter isVisible={showFilter} />
      <TableWrapper>
        <Table
          loading={isFetching}
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={vipRuleListResults.results}
          pagination={{
            pageSize: vipRuleListParams.limit,
            current: page,
            showSizeChanger: false,
            total: vipRuleListResults.count,
            onChange: fetchPageChange,
          }}
        />
        {vipRuleListResults.results.length > 0 && (
          <BatchDelete>
            <Button onClick={() => fetchBatchDelete(Array.from(checkIds))}>刪除</Button>
          </BatchDelete>
        )}
      </TableWrapper>
    </Wrapper>
  );
}
