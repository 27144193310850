import { ExclamationCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import { SlotTargetListItem } from "@api/slotApi";
import { configState } from "@redux/configSlice";
import { RootState } from "@redux/rootReducer";
import {
  batchDeleteSlotTarget,
  fetchSlotTargetList,
  setShowImportCsvPopup,
  singleDeleteSlotTarget,
  updateSelectedSlotTargetIds,
  updateSlotTarget,
  updateSlotTargetListParams,
} from "@redux/slotSlice";
import { Button, Modal, Pagination, Select, Switch, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import React, { FC, ReactText, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import { PageType, SlotPatternValue } from "../../constant";
import AddNewPopup from "./AddNewPopup";
import ImportCSV from "./ImportCSV";

const Wrapper = styled.div``;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-bottom: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  margin-bottom: 24px;
`;
const ButtonContainer = styled.div``;
const DeleteButton = styled(Button)`
  margin-right: 10px;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const DeleteText = styled.p`
  font-size: 13px;
  color: #1890ff;
  cursor: pointer;
  margin: 0;
`;
const Link = styled.a`
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
  cursor: pointer;
  margin: 0;
  text-decoration: underline;
`;

const CustomButton = styled(Button)`
  margin-left: 10px;
`;

const pageSizeOptions = [20, 50, 100];

const TargetTab: FC = () => {
  const dispatch = useAppDispatch();
  const { webDomain } = useSelector(configState);

  const { id: slotId } = useParams();
  const {
    slotTargetListResult,
    slotTargetListParams,
    selectedSlotTargetIds,
    isFetchingSlotTargetList,
    showImportCsvPopup,
  } = useSelector((state: RootState) => state.slot);
  const { slotPattern } = useSelector((state: RootState) => state.slot.slotDetail);

  const [showAddNewPopup, setShowAddNewPopup] = useState<boolean>(false);
  const [mode, setMode] = useState<"new" | "edit">();
  const [slotDetail, setSlotDetail] = useState<SlotTargetListItem>();

  const { count, results: slotTargetList } = slotTargetListResult.result;
  const { limit, offset } = slotTargetListParams;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    const params = {
      limit: 20,
      offset: 0,
    };

    dispatch(updateSlotTargetListParams(params));
    dispatch(fetchSlotTargetList());
  }, [dispatch, slotId]);

  const openAddNewPopup = () => {
    setShowAddNewPopup(true);
    setMode("new");
  };
  const closeAddNewPopup = () => {
    setShowAddNewPopup(false);
    setMode(undefined);
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const params = {
      ...slotTargetListParams,
      limit: value as number,
    };

    dispatch(updateSlotTargetListParams(params));
    dispatch(fetchSlotTargetList());
  };

  const handleOnPageChange = (page: number) => {
    const params = {
      ...slotTargetListParams,
      offset: (page - 1) * limit,
    };

    dispatch(updateSlotTargetListParams(params));
    dispatch(fetchSlotTargetList());
  };

  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSelectedSlotTargetIds(values as number[]));
  };

  const handleOnSwitch = (relationId: number): SwitchChangeEventHandler => (value) => {
    const params = {
      relationId,
      params: {
        toShow: value,
      },
    };
    dispatch(updateSlotTarget(params));
  };

  const handleOnSingleDelete = (slotTargetId: number) => () => {
    Modal.confirm({
      title: "你確定要刪除這筆資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(singleDeleteSlotTarget(slotTargetId));
      },
    });
  };
  const handleOnEdit = (slotTargetId: number, data: SlotTargetListItem) => () => {
    setMode("edit");
    setSlotDetail(data);
    setShowAddNewPopup(true);
  };

  const handleOnBatchDelete = () => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(batchDeleteSlotTarget());
      },
    });
  };

  const tableColumns = [
    {
      title: "啟用",

      key: "toShow",
      dataIndex: "toShow",
      width: 76,
      render: (value: SlotTargetListItem["toShow"], data: SlotTargetListItem) => (
        <Switch checked={value} onChange={handleOnSwitch(data.id)} />
      ),
    },
    {
      title: "頁面類型",
      key: "pageTypeName",
      dataIndex: "pageTypeName",
      width: 150,
    },
    {
      title: "頁面資訊",
      key: "pageInfo",
      dataIndex: "pageInfo",
      width: 100,
      render: (value: SlotTargetListItem["pageInfo"], data: SlotTargetListItem) => {
        switch (data.pageType) {
          case PageType.CATEGORY:
            return (
              <Link href={`${webDomain}/category/${value}`} target="_blank">
                {value}
              </Link>
            );
          case PageType.SALES_PAGE:
            return (
              <Link href={`${webDomain}/salespage/${value}`} target="_blank">
                {value}
              </Link>
            );
          case PageType.SEARCH:
            return (
              <Link href={`${webDomain}/search`} target="_blank">
                N/A
              </Link>
            );
          case PageType.TAG:
            return (
              <Link href={`${webDomain}/tag/${value}`} target="_blank">
                {value}
              </Link>
            );
          case PageType.TOPIC:
            return (
              <Link href={`${webDomain}/topic/${value}`} target="_blank">
                {value}
              </Link>
            );
          case PageType.TOPIC_CATEGORY:
            return (
              <Link href={`${webDomain}/topic-category/${value}`} target="_blank">
                {value}
              </Link>
            );
          default:
            return "N/A";
        }
      },
    },
    {
      title: "位置",
      key: "position",
      dataIndex: "position",
    },
    {
      title: "最後被編輯時間",
      key: "updatedAt",
      dataIndex: "updatedAt",
    },
    {
      title: "",
      key: "",
      dataIndex: "pageType",
      render: (value: any, data: SlotTargetListItem) => {
        if (value === PageType.ALL_SALES_PAGE || value === PageType.SALES_PAGE) return null;
        return <DeleteText onClick={handleOnEdit(data.id, data)}>編輯</DeleteText>;
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      render: (value: any, data: SlotTargetListItem) => (
        <DeleteText onClick={handleOnSingleDelete(data.id)}>刪除</DeleteText>
      ),
    },
  ];

  return (
    <Wrapper>
      <Header>
        總共
        {count}
        筆， 每頁顯示
        <PageSelect value={limit} onChange={handleOnPageSizeChange}>
          {pageSizeOptions.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </PageSelect>
        筆
      </Header>
      <TableContainer>
        <Table
          rowKey="id"
          dataSource={slotTargetList}
          columns={tableColumns}
          scroll={{ x: "max-content" }}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedSlotTargetIds,
          }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingSlotTargetList}
        />
      </TableContainer>
      <Footer>
        <ButtonContainer>
          <DeleteButton onClick={handleOnBatchDelete} disabled={selectedSlotTargetIds.length === 0}>
            刪除
          </DeleteButton>
          <Button type="primary" icon={<PlusCircleFilled />} onClick={openAddNewPopup}>
            新增
          </Button>
          {slotPattern === SlotPatternValue.CAROUSEL_PATTERN ||
          slotPattern === SlotPatternValue.FLAT_CAROUSEL_PATTERN ? (
            <CustomButton onClick={() => dispatch(setShowImportCsvPopup(true))}>批次匯入銷售頁</CustomButton>
          ) : null}
          <AddNewPopup mode={mode} slotDetail={slotDetail} show={showAddNewPopup} onClose={closeAddNewPopup} />
          {showImportCsvPopup && <ImportCSV />}
        </ButtonContainer>
        <Pagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </Footer>
    </Wrapper>
  );
};

export default TargetTab;
