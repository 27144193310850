import { ActionType, RedeemMethod, RedeemType } from "@api/vipRuleApi";
import PageTitle from "@component/PageTitle";
import {
  fetchCreateVipRule,
  fetchDownloadRedeemCode,
  fetchUpdateVipRule,
  fetchVipRule,
  VipRuleState,
} from "@redux/vipRuleSlice";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Tabs,
} from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 18px 14px 0px 14px;

  .ant-tabs {
    overflow: visible;
  }

  .ant-form-item-no-colon {
    width: 107px;
  }

  .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }

  .ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;

const CustomForm = styled(Form)`
  padding-left: 24px;
`;

const CustomInput = styled(Input)<{
  width?: string;
  gap?: boolean;
  marginBottom?: string;
  marginTop?: string;
  error?: boolean;
}>`
  width: ${({ width }) => width || "100%"};
  margin: ${({ gap }) => (gap ? "0 10px" : "")};
  margin-top: ${({ marginTop }) => marginTop || "0"};
  margin-bottom: ${({ marginBottom }) => marginBottom || "0"};
  border-color: ${({ error }) => error && "#ff4d4f"};
`;
const CustomInputNumber = styled(InputNumber)<{
  width?: string;
  gap?: boolean;
  marginBottom?: string;
  marginTop?: string;
  error?: boolean;
}>`
  width: ${({ width }) => width || "100%"};
  margin: ${({ gap }) => (gap ? "0 10px" : "")};
  margin-top: ${({ marginTop }) => marginTop || "0"};
  margin-bottom: ${({ marginBottom }) => marginBottom || "0"};
  border-color: ${({ error }) => error && "#ff4d4f"};
`;

const Scope = styled.div`
  padding: 12px 16px 78px 16px;
  border: solid 1px #f0f0f0;
`;

const Relative = styled.div`
  position: relative;
`;

const CodeScope = styled.div`
  background: #fafafa;
  padding: 18px 16px;
  min-height: 123px;
  max-width: 583px;
  width: 100%;
`;

const Notice = styled.div<{ marginTop?: string }>`
  color: ${({ theme }) => theme.colorNeutral400};
  margin-top: ${({ marginTop }) => marginTop || "0"};
  ${fontStyle("14px", "22px")};
`;

const Footer = styled(Row)`
  position: fixed;
  bottom: 0;
  left: 15.5%;
  width: 83.4%;
  padding: 12px 0;
  background: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
  padding-right: 36px;
`;

const CustomnSelect = styled(Select)<{ error?: boolean }>`
  margin-top: 12px;

  &&& {
    width: 173px;

    .ant-select-selector {
      border-color: ${({ error }) => error && "#ff4d4f"};
    }
  }
`;

const Content = styled.div`
  max-width: 637px;
  width: 100%;
  border: solid 1px ${({ theme }) => theme.colorNeutral300};
`;

const TableHeader = styled.div<{ redeemMethod?: RedeemMethod }>`
  background: #fafafa;
  display: grid;
  grid-template-columns: ${({ redeemMethod }) => {
    switch (redeemMethod) {
      case RedeemMethod.SINGLE:
        return "1fr 1fr 1fr";
      case RedeemMethod.RANDOM:
        return "1fr 1fr 1fr 1fr";
      default:
        return "";
    }
  }};
`;

const TableBody = styled(TableHeader)`
  background: transparent;
`;

const TableColumn = styled.div`
  padding: 4px 0 4px 12px;
  ${fontStyle("12px", "22px")};
`;

const RandomText = styled.span`
  margin-right: 19px;
`;

const ErrorText = styled.div<{ noAbsolute?: boolean }>`
  margin-top: 4px;
  color: #ff4d4f;
  position: ${({ noAbsolute }) => (noAbsolute ? "static" : "absolute")};
  top: 92%;
  left: 8px;
`;

const CustomRadio = styled(Radio)`
  display: inline-flex;
  align-items: center;

  & > span:last-child {
    display: flex;
    align-items: center;
  }
`;

const InlineBox = styled.div`
  display: inline-block;
  margin-right: 12px;
  position: relative;
`;
const InputStyled = styled(Input)<{ error: boolean }>`
  ${({ error }) => error && "border-color:#ff4d4f"};
`;

enum UsePeriodMethod {
  BETWEEN = 1,
  UNLIMITED = 2,
}

enum MaxUseMethod {
  UNLIMITED = 1,
  LIMITED = 2,
}

export default function SaleInformation() {
  const dispatch = useDispatch();
  const { redirectId, vipRuleInfo, isFetching, vipRuleError } = useSelector(VipRuleState);

  const navigate = useNavigate();
  const { vipRuleId } = useParams();

  const [form] = Form.useForm();

  const [formError, setFormError] = useState<{
    singleCodeInput: string;
    randomCodePrefixInput: string;
    randomCodeNumber: string;
    numOfCodesError: string;
    discountInput: string;
    extendDaysInput: string;
    useTimePeriod: string;
    perCodeLimitInput: string;
  }>({
    singleCodeInput: "",
    randomCodePrefixInput: "",
    randomCodeNumber: "",
    numOfCodesError: "",
    discountInput: "",
    extendDaysInput: "",
    useTimePeriod: "",
    perCodeLimitInput: "",
  });
  const [inputDisabled, setInputDisabled] = useState<{ [key: string]: boolean }>({});
  const generateNumberList = (start: number, end: number) => {
    return Array(end - start + 1)
      .fill(null)
      .map((_, idx) => start + idx);
  };

  const onChangeInput = (errorField: string, fn: () => void) => {
    setFormError((prev) => ({ ...prev, [errorField]: "" }));
    fn();
  };

  const onSubmit = (values: any) => {
    try {
      const errors: {
        singleCodeInput: string;
        randomCodeNumber: string;
        numOfCodesError: string;
        discountInput: string;
        extendDaysInput: string;
        useTimePeriod: string;
        perCodeLimitInput: string;
      } = {
        singleCodeInput: "",
        randomCodeNumber: "",
        numOfCodesError: "",
        discountInput: "",
        extendDaysInput: "",
        useTimePeriod: "",
        perCodeLimitInput: "",
      };

      if (values.redeemMethod === RedeemMethod.SINGLE && !values.redeemCode) {
        errors.singleCodeInput = "欄位不能為空";
      }

      if (values.redeemMethod === RedeemMethod.RANDOM && !values.codeLength) {
        errors.randomCodeNumber = "欄位不能為空";
      }
      if (values.redeemMethod === RedeemMethod.RANDOM && !values.numOfCodes) {
        errors.numOfCodesError = "欄位不能為空";
      }

      if (values.actionType === ActionType.DISCOUNT && !values.discount) {
        errors.discountInput = "欄位不能為空";
      }

      if (values.actionType === ActionType.EXTEND_DAY && !values.extendDays) {
        errors.extendDaysInput = "欄位不能為空";
      }

      if (values.usePeriodMethod === UsePeriodMethod.BETWEEN && !values.period) {
        errors.useTimePeriod = "欄位不能為空";
      }

      if (values.maxUseMethod === MaxUseMethod.LIMITED && !values.perCodeLimit) {
        errors.perCodeLimitInput = "欄位不能為空";
      }

      setFormError((prev) => ({ ...prev, ...errors }));

      let canSubmit = true;
      for (const value of Object.values(errors)) {
        if (value) {
          canSubmit = false;
        }
      }

      if (!canSubmit && !vipRuleInfo) return false;

      if (vipRuleInfo) {
        const payload = {
          title: values.title,
          startAt:
            values.usePeriodMethod === UsePeriodMethod.BETWEEN ? values.period[0].format("YYYY-MM-DD HH:mm:ss") : "",
          endAt:
            values.usePeriodMethod === UsePeriodMethod.BETWEEN ? values.period[1].format("YYYY-MM-DD HH:mm:ss") : "",
          maxUseTimes: values.maxUseTimes ? parseInt(values.maxUseTimes, 10) : undefined,
          isActive: values.isActive,
        };

        dispatch(fetchUpdateVipRule({ payload, vipRuleId: parseInt(vipRuleId, 10) }));
      } else {
        const payload = {
          title: values.title,
          redeemMethod: values.redeemMethod,
          actionType: values.actionType,
          discount:
            values.actionType === ActionType.DISCOUNT ? parseInt(values.discount, 10) : parseInt(values.extendDays, 10),
          startAt:
            values.usePeriodMethod === UsePeriodMethod.BETWEEN
              ? values.period[0].format("YYYY-MM-DD HH:mm:ss")
              : undefined,
          endAt:
            values.usePeriodMethod === UsePeriodMethod.BETWEEN
              ? values.period[1].format("YYYY-MM-DD HH:mm:ss")
              : undefined,
          codePrefix: values.redeemMethod === RedeemMethod.RANDOM ? values.codePrefix : undefined,
          codeLength: values.redeemMethod === RedeemMethod.RANDOM ? values.codeLength : undefined,
          redeemCode: values.redeemMethod === RedeemMethod.SINGLE ? values.redeemCode : undefined,
          isActive: !!values.isActive,
          perCodeLimit: values.maxUseMethod === MaxUseMethod.LIMITED ? parseInt(values.perCodeLimit, 10) : undefined,
          numOfCodes: values.redeemMethod === RedeemMethod.RANDOM ? parseInt(values.numOfCodes, 10) : undefined,
          redeem_type: values.redeemType,
        };
        dispatch(fetchCreateVipRule(payload));
      }
      return true;
    } catch (error: any) {
      return false;
    }
  };

  const onRedeemTypeChange = (value: RedeemType) => {
    switch (value) {
      case RedeemType.BUY_VIP:
        setInputDisabled({ redeemMethodSingle: false, discount: false, unlimited: false });
        break;
      case RedeemType.PROMOTION:
        // *   Redeem Code disabled 單一、限定隨機
        // *   優惠內容 disabled 折抵金額、延長到期 30天
        // *   使用次數 disabled 不限、限制 1次
        form.setFieldsValue({
          redeemMethod: RedeemMethod.RANDOM,
          actionType: ActionType.EXTEND_DAY,
          extendDays: 30,
          maxUseMethod: MaxUseMethod.LIMITED,
          perCodeLimit: 1,
        });
        setInputDisabled({
          redeemMethodSingle: true,
          discount: true,
          unlimited: true,
          extendDays: true,
          perCodeLimit: true,
        });
        break;
      default:
    }
  };

  useEffect(() => {
    if (vipRuleId && vipRuleId !== "new") {
      dispatch(fetchVipRule(parseInt(vipRuleId, 10)));
    }
  }, [dispatch, vipRuleId]);

  useEffect(() => {
    if (vipRuleInfo) {
      form.setFieldsValue({
        ...vipRuleInfo,
        discount: vipRuleInfo.actionType === ActionType.DISCOUNT ? vipRuleInfo.discount : undefined,
        extendDays: vipRuleInfo.actionType === ActionType.EXTEND_DAY ? vipRuleInfo.discount : undefined,
        period: vipRuleInfo.useStartAt ? [moment(vipRuleInfo.useStartAt), moment(vipRuleInfo.useEndAt)] : [],
        usePeriodMethod: vipRuleInfo.useStartAt ? UsePeriodMethod.BETWEEN : UsePeriodMethod.UNLIMITED,
        maxUseMethod: vipRuleInfo.perCodeLimit ? MaxUseMethod.LIMITED : MaxUseMethod.UNLIMITED,
      });
    } else {
      form.setFieldsValue({
        redeemMethod: RedeemMethod.SINGLE,
        actionType: ActionType.DISCOUNT,
        usePeriodMethod: UsePeriodMethod.BETWEEN,
        maxUseMethod: MaxUseMethod.UNLIMITED,
      });
    }
  }, [form, vipRuleInfo]);

  useEffect(() => {
    if (redirectId) {
      navigate(`/vip-rule/edit/${redirectId}/`);
    }
  }, [redirectId, navigate]);

  useEffect(() => {
    if (vipRuleError) {
      message.error(vipRuleError.replace("max_use_times ", "可使用人數"));
    }
  }, [vipRuleError]);

  return (
    <Spin spinning={isFetching}>
      <PageTitle title={`VIP 優惠 - ${vipRuleInfo?.title || "新增"}`} />
      <Wrapper>
        <Scope>
          <Tabs type="card">
            <Tabs.TabPane tab="VIP優惠內容">
              <CustomForm
                form={form}
                colon={false}
                wrapperCol={{ span: 12 }}
                labelCol={{ span: 3 }}
                labelAlign="left"
                onFinish={onSubmit}
                onFinishFailed={showRequiredFieldsWarning}
              >
                <Form.Item label="啟用" name="isActive" valuePropName="checked">
                  <Switch />
                </Form.Item>
                <Form.Item label="ID" name="id">
                  <CustomInput width="200px" disabled />
                </Form.Item>
                <Form.Item label="名稱" name="title" rules={[{ required: true, message: "必填" }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="條件" name="redeemType" rules={[{ required: true, message: "必填" }]}>
                  <Select
                    onChange={onRedeemTypeChange}
                    disabled={!!vipRuleInfo}
                    options={[
                      { label: "搭配VIP會籍購買", value: RedeemType.BUY_VIP },
                      { label: "單獨兌換VIP會籍", value: RedeemType.PROMOTION },
                    ]}
                  />
                </Form.Item>

                <Relative>
                  <Form.Item label="Redeem Code" name="redeemMethod">
                    <Radio.Group
                      value={form.getFieldValue("redeemMethod")}
                      disabled={!!vipRuleInfo}
                      onChange={(e) => {
                        const { value } = e.target;
                        const isRANDOM = value === RedeemMethod.RANDOM;
                        form.setFieldsValue({
                          maxUseMethod: MaxUseMethod.LIMITED,
                          perCodeLimit: isRANDOM ? 1 : undefined,
                        });
                        setInputDisabled({
                          ...inputDisabled,
                          perCodeLimit: !!isRANDOM,
                        });
                      }}
                    >
                      <Radio value={RedeemMethod.SINGLE} disabled={inputDisabled.redeemMethodSingle}>
                        單一
                      </Radio>
                      <Radio value={RedeemMethod.RANDOM}>
                        <RandomText>隨機</RandomText>
                        <InlineBox>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) =>
                              getFieldValue("redeemMethod") === RedeemMethod.RANDOM && (
                                <Col>
                                  <Form.Item name="numOfCodes" noStyle>
                                    <InputStyled
                                      placeholder="請輸入折扣碼數量"
                                      suffix="組"
                                      error={!!formError.numOfCodesError}
                                      onChange={() => onChangeInput("numOfCodesError", () => {})}
                                      disabled={!!vipRuleInfo}
                                    />
                                  </Form.Item>
                                </Col>
                              )
                            }
                          </Form.Item>
                          <ErrorText noAbsolute={false}>{formError.numOfCodesError}</ErrorText>
                        </InlineBox>
                        {vipRuleInfo?.redeemMethod === RedeemMethod.RANDOM && (
                          <InlineBox>
                            <Button
                              onClick={() =>
                                dispatch(fetchDownloadRedeemCode({ membershipRule: parseInt(vipRuleId, 10) }))
                              }
                            >
                              匯出
                            </Button>
                          </InlineBox>
                        )}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  {!vipRuleInfo ? (
                    <Form.Item label=" " shouldUpdate>
                      {({ getFieldValue, setFieldsValue }) =>
                        getFieldValue("redeemMethod") === RedeemMethod.SINGLE ? (
                          <CodeScope>
                            <CustomInput
                              width="80%"
                              minLength={7}
                              maxLength={16}
                              error={!!formError.singleCodeInput}
                              value={form.getFieldValue("redeemCode")}
                              onChange={(e) =>
                                onChangeInput("singleCodeInput", () => setFieldsValue({ redeemCode: e.target.value }))
                              }
                            />
                            <ErrorText noAbsolute>{formError.singleCodeInput}</ErrorText>
                            <Notice marginTop="11px">*僅限輸入大小寫英文、數字, 且限制7~16字</Notice>
                          </CodeScope>
                        ) : (
                          <CodeScope>
                            <CustomInput
                              width="80%"
                              placeholder="請輸入前綴詞"
                              value={form.getFieldValue("codePrefix")}
                              onChange={(e) =>
                                onChangeInput("randomCodePrefixInput", () =>
                                  setFieldsValue({ codePrefix: e.target.value }),
                                )
                              }
                            />
                            <CustomnSelect
                              defaultValue={-100}
                              error={!!formError.randomCodeNumber}
                              value={form.getFieldValue("codeLength")}
                              onChange={(val) =>
                                onChangeInput("randomCodeNumber", () => setFieldsValue({ codeLength: val as number }))
                              }
                            >
                              <Select.Option value={-100}>請選擇</Select.Option>
                              {generateNumberList(7, 16).map((item) => (
                                <Select.Option key={Math.random().toString(36).substring(7)} value={item}>
                                  {item}
                                </Select.Option>
                              ))}
                            </CustomnSelect>
                            <ErrorText noAbsolute>{formError.randomCodeNumber}</ErrorText>
                            <Notice marginTop="16px">*前綴詞僅限輸入大小寫英文、數字，且需在0~10字以內</Notice>
                            <Notice marginTop="8px">*若不輸入前綴詞與亂數個數，儲存後系統將自動產生兌換碼</Notice>
                          </CodeScope>
                        )
                      }
                    </Form.Item>
                  ) : (
                    <Form.Item label=" ">
                      {vipRuleInfo.redeemMethod === RedeemMethod.SINGLE ? (
                        <Content>
                          <TableHeader redeemMethod={vipRuleInfo.redeemMethod}>
                            <TableColumn>序號</TableColumn>
                            <TableColumn>已使用人數</TableColumn>
                          </TableHeader>
                          <TableBody redeemMethod={vipRuleInfo.redeemMethod}>
                            <TableColumn>{vipRuleInfo.redeemCode}</TableColumn>
                            <TableColumn>{vipRuleInfo.useCount}</TableColumn>
                          </TableBody>
                        </Content>
                      ) : (
                        <Content>
                          <TableHeader redeemMethod={vipRuleInfo.redeemMethod}>
                            <TableColumn>前綴詞</TableColumn>
                            <TableColumn>亂數個數</TableColumn>
                            <TableColumn>可使用人數</TableColumn>
                            <TableColumn>已使用人數</TableColumn>
                          </TableHeader>
                          <TableBody redeemMethod={vipRuleInfo.redeemMethod}>
                            <TableColumn>{vipRuleInfo.codePrefix}</TableColumn>
                            <TableColumn>{vipRuleInfo.codeLength}</TableColumn>
                            <TableColumn>{vipRuleInfo.perCodeLimit || "不限"}</TableColumn>
                            <TableColumn>{vipRuleInfo.useCount}</TableColumn>
                          </TableBody>
                        </Content>
                      )}
                    </Form.Item>
                  )}
                </Relative>
                <Form.Item name="redeemCode" noStyle />
                <Form.Item name="codePrefix" noStyle />
                <Form.Item name="codeLength" noStyle />
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue, setFieldsValue }) => (
                    <Form.Item label="優惠內容" name="actionType" wrapperCol={{ span: 12 }}>
                      <Radio.Group value={getFieldValue("actionType")} disabled={!!vipRuleInfo}>
                        <CustomRadio value={ActionType.DISCOUNT} disabled={inputDisabled.discount}>
                          <span>折抵金額</span>
                          <Relative>
                            <CustomInput
                              width="65px"
                              gap
                              type="number"
                              error={!!formError.discountInput}
                              disabled={inputDisabled.discount || !!vipRuleInfo}
                              value={getFieldValue("discount")}
                              onChange={(e) =>
                                onChangeInput("discountInput", () => setFieldsValue({ discount: e.target.value }))
                              }
                            />
                            <ErrorText>{formError.discountInput}</ErrorText>
                          </Relative>
                          <span>元</span>
                        </CustomRadio>
                        <CustomRadio value={ActionType.EXTEND_DAY}>
                          <span>延長到期</span>
                          <Relative>
                            <CustomInputNumber
                              width="65px"
                              gap
                              type="number"
                              error={!!formError.extendDaysInput}
                              disabled={inputDisabled.extendDays || !!vipRuleInfo}
                              value={getFieldValue("extendDays")}
                              onChange={(value) =>
                                onChangeInput("extendDaysInput", () => setFieldsValue({ extendDays: value }))
                              }
                              max={365}
                            />
                            <ErrorText>{formError.extendDaysInput}</ErrorText>
                          </Relative>
                          <span>天</span>
                        </CustomRadio>
                      </Radio.Group>
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item name="discount" noStyle />
                <Form.Item name="extendDays" noStyle />
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue, setFieldsValue }) => (
                    <Form.Item label="使用時間" name="usePeriodMethod">
                      <Radio.Group value={getFieldValue("usePeriodMethod")}>
                        <CustomRadio value={UsePeriodMethod.BETWEEN}>
                          <span>時間區間</span>
                          <Relative>
                            <DatePicker.RangePicker
                              locale={locale}
                              format="YYYY-MM-DD HH:mm:ss"
                              showTime={{
                                hideDisabledOptions: true,
                                defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
                              }}
                              style={{
                                width: "380px",
                                margin: "0 10px",
                                borderColor: formError.useTimePeriod && "#ff4d4f",
                              }}
                              value={getFieldValue("period")}
                              onChange={(period) => onChangeInput("useTimePeriod", () => setFieldsValue({ period }))}
                            />
                            <ErrorText>{formError.useTimePeriod}</ErrorText>
                          </Relative>
                        </CustomRadio>
                        <Radio value={UsePeriodMethod.UNLIMITED}>不限</Radio>
                      </Radio.Group>
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item name="period" noStyle />
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue, setFieldsValue }) => (
                    <Form.Item label="使用次數" name="maxUseMethod">
                      <Radio.Group>
                        <Radio
                          value={MaxUseMethod.UNLIMITED}
                          disabled={
                            (vipRuleInfo && vipRuleInfo.perCodeLimit !== null) ||
                            getFieldValue("redeemMethod") === RedeemMethod.RANDOM ||
                            inputDisabled.unlimited
                          }
                        >
                          不限
                        </Radio>
                        <CustomRadio
                          value={MaxUseMethod.LIMITED}
                          disabled={vipRuleInfo && vipRuleInfo.perCodeLimit == null}
                        >
                          <span>限制</span>
                          <Relative>
                            <CustomInput
                              width="65px"
                              gap
                              type="number"
                              error={!!formError.perCodeLimitInput}
                              value={getFieldValue("perCodeLimit")}
                              onChange={(e) =>
                                onChangeInput("perCodeLimitInput", () =>
                                  setFieldsValue({ perCodeLimit: e.target.value }),
                                )
                              }
                              disabled={inputDisabled.perCodeLimit || !!vipRuleInfo}
                            />
                            <ErrorText>{formError.perCodeLimitInput}</ErrorText>
                          </Relative>
                          <span>次</span>
                        </CustomRadio>
                      </Radio.Group>
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item name="perCodeLimit" noStyle />
                <Footer justify="end">
                  <Button type="primary" htmlType="submit">
                    {vipRuleId ? "儲存此分頁" : "建立資料"}
                  </Button>
                </Footer>
              </CustomForm>
            </Tabs.TabPane>
          </Tabs>
        </Scope>
      </Wrapper>
    </Spin>
  );
}
